<template>
    <v-container fluid class="px-8">
        <!-- 增加试题按钮 -->
        <div class="add-btn-row">
            <div>
                <v-btn
                    tile
                    dark
                    color="cyan darken-1"
                    class="font-weight-bold text-body-1"
                    @click.stop="openEditQuestionDlg()"
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    增加试题
                </v-btn>
            </div>
            <div>
                <v-btn-toggle
                    v-model="questionType"
                    tile
                    dense
                    borderless
                    mandatory
                >
                    <!-- <v-btn value=""  @click.stop="selectQuestionType('')">全部</v-btn> -->
                    <v-btn value="1" @click.stop="selectQuestionType('1')">选择</v-btn>
                    <v-btn value="2" @click.stop="selectQuestionType('2')">判断</v-btn>
                    <v-btn value="3" @click.stop="selectQuestionType('3')">实操</v-btn>
                </v-btn-toggle>
            </div>
        </div>

        <!-- 列表 -->
        <div class="table-wrapper">
            <v-data-table
                :headers="headers"
                :items="datas"
                :items-per-page-options="[5, 10, 15, -1]"
                class="elevation-2 row-pointer mt-5 table-bottom"
                :hide-default-footer="!datas || datas.length <= 10"
                :footer-props="{
                    showFirstLastPage: true,
                    itemsPerPageText: '每页行数',
                    pageText: '',
                    'items-per-page-all-text': '全部',
                }"
                @click:row="clickRow"
            >
                <template v-slot:item.type_text="{ item }">
                    <v-chip :color="getTypeColor(item.type_text)" dark >
                        {{ getTypeFirstLetter(item.type_text) }}
                    </v-chip>
                </template>
                <template v-slot:item.operate="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs" v-on="on"
                                class="mx-2"
                                icon
                                height="32"
                                width="32"
                                outlined
                                x-small
                                elevation="2"
                                color="purple"
                                @click.stop="openEditQuestionDlg(item)"
                            >
                                <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                        <span>编辑试题</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs" v-on="on"
                                class="mx-2"
                                icon
                                height="32"
                                width="32"
                                outlined
                                x-small
                                elevation="2"
                                color="red darken-1"
                                @click.stop="openConfirmDlg('delQuestion', item)"
                            >
                                <v-icon dark> mdi-trash-can </v-icon>
                            </v-btn>
                        </template>
                        <span>删除试题</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <div class="page-wrapper">
                <!-- <v-pagination
                    elevation="0"
                    v-model="page"
                    :length="6"
                ></v-pagination> -->
            </div>
        </div>

        <!-- 编辑选择题 对话框 -->
        <EditQuestionDlg4Choice ref="editQuestionDlg4Choice" @submit="submitQuestion" />
        <!-- 编辑判断题 对话框 -->
        <EditQuestionDlg4Judge ref="editQuestionDlg4Judge" @submit="submitQuestion" />
        <!-- 编辑实操题 对话框 -->
        <EditQuestionDlg4Operate ref="editQuestionDlg4Operate" @submit="submitQuestion" />
        <!-- 删除试题 对话框 -->
        <ConfirmDlg
            ref="delQuestionConfirmDlg"
            title="删除试题"
            text="确认 删除 当前试题么？"
            keyword=" 删除 "
            @confirm="delQuestionConfirm"
        />

    </v-container>
</template>

<script>
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';
import EditQuestionDlg4Choice from "@/views/components/dialog/EditQuestionDlg4Choice";
import EditQuestionDlg4Judge from "@/views/components/dialog/EditQuestionDlg4Judge";
import EditQuestionDlg4Operate from "@/views/components/dialog/EditQuestionDlg4Operate";
import { getText, getAvatar, getImgUrl, getTypeFirstLetter } from "@/utils/util";
import { getQuestionsFromLib, editQuestion, delQuestion } from "@/api/admin_exam"
import { getTypeColor } from '@/utils/common';

export default {
    name: "QuestionList",
    data() {
        return {
            defaultImg4Topic: require("@/assets/images/admin/default-cover.jpeg"),
            questionType: "1", // '': 全部, 1: 选择，2:判断，3:实操
            headers: [
                { text: "题型", value: "question_type_text", width: 80, align: "start", sortable: false },
                { text: "类型", value: "type_text", width: 80, align: "start" },
                // { text: "级别", value: "level_text", width: 80, align: "start", sort: (a, b) => a.localeCompare(b) },
                { text: "级别", value: "level_text", width: 80, align: "start", sort: (a, b) => this.compareLevel(a, b) },
                { text: "题目", value: "question_text", align: "start", sortable: false },
                // { text: "考察维度", value: "dimension_name", width: 100, sortable: false },
                { text: "操作", value: "operate", sortable: false, align: "center", width: 130},
            ],
            datas: [],
            libId: "",
        };
    },
    activated() {
        if (this.$route.query.id) {
            this.libId = this.$route.query.id;
        }
        this.loadData();
    },

    methods: {
        getText,
        getAvatar,
        getImgUrl,
        getTypeColor,
        getTypeFirstLetter,
        loadData() {
            getQuestionsFromLib(this.libId, this.questionType).then((response) => {
                // console.log(response.data);
                this.datas = response.data;

            }).catch(function (err) {
                console.log(err);
            });
        },
        selectQuestionType(questionType) {
            this.questionType = questionType

            getQuestionsFromLib(this.libId, this.questionType).then((response) => {
                this.datas = response.data;

            }).catch(function (err) {
                console.log(err);
            });
        },

        // 对话框
        openEditQuestionDlg(question = null) {
            let questionType = parseInt(this.questionType)
            if (question) {
                questionType = question.question_type
            }
            switch (questionType) {
                case 1:
                    this.$refs.editQuestionDlg4Choice.openDlg(question);
                    break;
                case 2:
                    this.$refs.editQuestionDlg4Judge.openDlg(question);
                    break;
                case 3:
                    this.$refs.editQuestionDlg4Operate.openDlg(question);
                    break;
                default:
                    break;
            }
        },
        openConfirmDlg(type, params) {
            if (type == "delQuestionLib") {
                this.$refs.delQuestionLibConfirmDlg.openDlg(params);
            } else if (type == "delQuestion") {
                this.$refs.delQuestionConfirmDlg.openDlg(params);
            }
        },

        // 编辑试题
        async submitQuestion(question) {
            editQuestion(this.libId, question).then((response) => {
                // console.log(response.data);
                if (response.msg == "SUCCESS") {
                    // 更新列表数据
                    let newData = response.data
                    if (question.qid == '') {
                        this.datas.unshift(newData)
                    } else {
                        for (let i in this.datas) {
                            if (this.datas[i].qid == question.qid) {
                                this.datas[i] = newData
                                this.$set(this.datas, i, this.datas[i]);
                                break
                            }
                        }
                    }
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "新增/编辑试题成功"});
                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "新增/编辑试题失败" });
                }

            }).catch(function (err) {
                console.log(err);
            });
        },
        async delQuestionConfirm(params) {
            // console.log(params)
            let response = await delQuestion(this.libId, params.question_type, params.qid)
            if (response.msg == "SUCCESS") {
                this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除试题成功"});

                // 删除列表中的试题
                let index = -1;
                for (let i in this.datas) {
                    if (this.datas[i].qid == params.qid) {
                        index = i;
                        break;
                    }
                }
                if (index >= 0) {
                    this.datas.splice(index, 1);
                }

            } else {
                this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除试题失败" });
            }
        },
        
        convLevel2Num(level) {
            let num = level.substring(0,1)
            switch (num) {
                case '一':
                    return 1
                case '二':
                    return 2
                case '三':
                    return 3
                case '四':
                    return 4
                case '五':
                    return 5
                case '六':
                    return 6
                default:
                    return 0
            }
        },
        compareLevel(a, b) {
            let a2 = this.convLevel2Num(a.substring(0,1))
            let b2 = this.convLevel2Num(b.substring(0,1))
            return a2 - b2
        },
        clickRow(item) {
            this.openEditQuestionDlg(item);
        },
    },
    components: {
        ConfirmDlg,
        EditQuestionDlg4Choice,
        EditQuestionDlg4Judge,
        EditQuestionDlg4Operate,
    }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
    position: relative;
    .page-wrapper {
        position: absolute;
        bottom: 6px;
    }
}
.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.avatar {
    height: 45px;
    width: 45px;
    img {
        height: 100%;
        width: 100%;
    }
}
.course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course-desc {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .desc-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 20px;
        }
        .desc-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            .desc-name {
                width: 70px;
                min-width: 70px;
            }
        }
        .text {
            text-align: left;
            font-weight: 300;
            font-size: 14px;
        }
    }
}
.operate {
    // height: 125px;
    height: 100%;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table-bottom {
    margin-bottom: 80px;
}

.v-btn-toggle > .v-btn.v-btn {
    // margin: 0;
    padding: 0 20px;
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
