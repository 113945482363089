<template>
    <div
        class="upload-container"
        :style="{ width: width, height: height }"
    >
        <div class="progress-wrapper" v-if="isUploading">
            <v-progress-circular
                :rotate="-90"
                :size="20"
                :width="5"
                :value="progress"
                color="white"
            >
                {{ progress }}
            </v-progress-circular>
            <div class="progress-text">
                正在上传文件
            </div>
        </div>
        <div class="upload-wrapper" v-else>
            <input
                class="upload-input"
                ref="input"
                type="file"
                @change="handleFiles"
            />
            <div class="upload-trigger" 
                :class="{ dragging }"
                @click.stop="onClickTrigger"
                @dragenter="onDragenter"
                @dragleave="onDragleave"
                @dragover="onDragover"
                @drop="onDrop"
            >
                <div class="upload-btn">
                    <v-icon dark size="24" color="grey"> mdi-plus </v-icon>
                    <div class="text">上传图片</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import qiniu from '@/utils/qiniu'

export default {
    props: {
        cosName: { type: String, default: "" },
        prefix: { type: String, default: "" },
        filename: { type: String, default: "file" },
        // type: { type: String, default: "" },

        width: { type: String, default: "100%" },
        height: { type: String, default: "45px" },
    },
    data() {
        return {
            files: [],
            dragging: false,

            progress: 0,
            isUploading: false,
        };
    },
    methods: {
        onClickTrigger() {
            this.$refs.input.click();
        },
        handleFiles(e) {
            // e.target.files is pseudo array, need to convert to real array
            const rawFiles = Array.from(e.target.files);
            console.log(rawFiles);
            this.uploadFiles(rawFiles);
        },
        uploadFiles(rawFiles) {
            const filesLen = rawFiles.length + this.files.length;
            if (this.limit && this.limit < filesLen) {
                return this.onExceed(rawFiles, this.files);
            }
            this.startUpload(rawFiles);
        },
        startUpload(rawFiles) {
            rawFiles.forEach((rawFile) => {
                const file = this.normalizeFiles(rawFile);
                console.log(file)
                if (!this.beforeUpload || this.beforeUpload()) {
                    this.upload(file);
                }
            });
        },
        normalizeFiles(rawFile) {
            const file = {
                name: rawFile.name, // 文件名
                size: rawFile.size, // 文件尺寸
                type: rawFile.type, // 文件类型
                percent: 0, // 上传进度
                uid: Date.now() + this.tempIndex++, // 唯一标识
                status: "init", // value list: init pending success failure
                raw: rawFile, // 原生文件对象
            };
            // concat does not change the existing arrays, but instead returns a new array
            this.files.push(file);
            return file;
        },
        async upload(file) {
            let that = this

            if (this.checkFileType(file.type)) {

                this.isUploading = true

                if (file) {
                    // 上传到七牛
                    await qiniu.uploadFile(file.raw, this.prefix, that.uploadSuccess)
                    
                } else {
                    // TODO: 增加验证提示
                    console.log("请先上传图片")
                }
            }
        },
        checkFileType(type) {
            // TODO 增加支持的文件类型校验
            return true
        },
        onProgress(progress) {
            this.progress = Number(progress.loaded / progress.total).toFixed(2) * 100
        },

        // 拖拽上传
        onDragenter(e) {
            this.dragging = true;
            e.stopPropagation();
            e.preventDefault();
        },
        onDragleave(e) {
            this.dragging = false;
            e.stopPropagation();
            e.preventDefault();
        },
        onDragover(e) {
            e.stopPropagation();
            e.preventDefault();
        },
        onDrop(e) {
            this.dragging = false;
            e.stopPropagation();
            e.preventDefault();

            const rawFiles = Array.from(e.dataTransfer.files);
            console.log(rawFiles);
            this.uploadFiles(rawFiles);
        },
        uploadSuccess(id) {
            console.log(id)
            this.$emit('uploadSuccess', id);
            this.isUploading = false
            this.progress = 0
        }
    },
};
</script>

<style lang="scss" scoped>
.upload-container {
    border: 2px dashed #ddd;
    // border-radius: 8px;
    // padding: 12px 12px;
    // margin-top: 12px;

    .upload-wrapper {
        width: 100%;
        height: 100%;
    }

    .upload-input {
        display: none;
    }

    .upload-trigger {
        // width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .text {
            font-size: 14px;
            color: #555;
            letter-spacing: 1px;
            margin-left: 5px;
        }

        .upload-btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 8px;
        }
    }
    .upload-trigger:hover {
        background-color: #f6f6f6;
    }

    .dragging {
        background-color: #f6f6f6;
    }

    .progress-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .progress-text {
            font-size: 16px;
            font-weight: bold;
            color: #888;
            margin-left: 12px;
        }
    }
}

.cross {
    margin: 10px 0;
    background: #ddd;
    height: 15px;
    position: relative;
    width: 10px;
}
.cross:after {
    background: #ddd;
    content: "";
    height: 10px;
    left: -25px;
    position: absolute;
    top: 25px;
    width: 20px;
}
</style>
