<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="800">
            <v-card class="px-4 pt-3">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold"> {{ isReadOnly ? "查看试题（不可编辑）" : "编辑试题" }}</span>
                </v-card-title>
                <div class="form-content mx-12 dlg-content">
                    <v-form v-model="valid" ref="form" class="">
                        <div class="form-row top">
                            <!-- 题干文字 -->
                            <div class="col-one">
                                <v-textarea
                                    class="no-scroll"
                                    outlined
                                    label="题目"
                                    v-model="question_text"
                                    height="120"
                                    required
                                    :rules="[v => !!v || '请填写题目内容']"
                                ></v-textarea>
                            </div>
                            <!-- 题干图片 -->
                            <div class="col-two">
                                <div class="option-img" v-if="question_img != ''">
                                    <v-img
                                        :src="getImgUrl4Exam(question_img, 200, 132)"
                                        height="132"
                                        width="200"
                                        contain
                                    ></v-img>
                                    <v-icon color="#ccc" size="24" class="ml-2" @click.stop="delImg('question')"> mdi-close-circle </v-icon>
                                </div>
                                <UploadSmall
                                    v-else
                                    :prefix="prefix"
                                    @uploadSuccess="uploadSuccess4Question"
                                ></UploadSmall>
                            </div>
                        </div>

                        <div class="form-row mt-5">
                            <div class="form-label mr-10" style="width: 30px">答案</div>
                            <v-radio-group dense row v-model="answer" :rules="[v => !!v || '请选择答案']" required lazy-validation>
                                <v-radio key="1" label="正确" value="1" ></v-radio>
                                <v-radio key="2" label="错误" value="2" ></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="form-row">
                            <div class="col-one middle">
                                <div class="form-label mr-10" style="width: 30px">类型</div>
                                <v-select
                                    v-model="type"
                                    :items="typeMenu"
                                    label="类型"
                                    required
                                    :rules="[v => !!v || '请选择类型']"
                                ></v-select>
                            </div>
                            <div class="col-two middle">
                                <div class="form-label mr-10" style="width: 30px">级别</div>
                                <v-select
                                    v-model="level"
                                    :items="levelMenu"
                                    label="级别"
                                    required
                                    :rules="[v => !!v || '请选择级别']"
                                ></v-select>
                            </div>
                        </div>

                        <!-- <div class="form-row">
                            <div class="form-label mr-10">级别</div>
                            <v-select
                                v-model="level"
                                :items="levelMenu"
                                label="级别"
                                required
                                :rules="[v => !!v || '请选择级别']"
                            ></v-select>
                        </div> -->

                        <!-- <div class="form-row">
                            <div class="form-label mr-10">考察维度</div>
                            <v-radio-group dense row v-model="dimension" :rules="[v => !!v || '请选择考察维度']" required>
                                <v-radio key="1" label="知识掌握" value="1" ></v-radio>
                                <v-radio key="2" label="技能运用" value="2" ></v-radio>
                                <v-radio key="3" label="信息文化" value="3" ></v-radio>
                                <v-radio key="4" label="创作实践" value="4" ></v-radio>
                            </v-radio-group>
                        </div> -->
                    </v-form>
                    <!-- <div class="readonly-mask"></div> -->
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <template v-if="!isReadOnly">
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                    </template>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { getImgUrl4Exam } from "@/utils/util"
import { deleteImage } from "@/api/admin";
import UploadSmall from '@/views/components/widget/UploadSmall';

export default {
    name: "EditQuestionDlg4Judge",

    mixins: [validationMixin],
    validations: {
        question_text: { required },
        answer: { required },
        type: { required },
        level: { required },
        dimension: { required },
    },

    data: function () {
        return {
            dialog: false,

            // 1:选择题, 2:判断题, 3:实操题
            question_type: 2,

            // 数据
            qid: '',
            question_text: '',
            question_img: '',
            answer: '',
            type: 0,
            // type_text: '',
            level: 0,
            // level_text: '',
            dimension: '',
            dimension_name: '',

            levelMenu: [
                { text: '一级', value: 1 },
                { text: '二级', value: 2 },
                { text: '三级', value: 3 },
                { text: '四级', value: 4 },
                { text: '五级', value: 5 },
                { text: '六级', value: 6 },
            ],
            typeMenu: [
                { text: 'Scratch', value: 1 },
                { text: 'Python',  value: 2 },
                { text: 'C/C++',   value: 3 },
                { text: 'Blockly', value: 4 },
                { text: 'EV3', value: 5 },
                { text: 'Scratch硬件', value: 6 },
                { text: 'Python硬件',  value: 7 },
            ],

            prefix: 'exam',
            valid: true,

            isReadOnly: false,
        };
    },
    computed: {
    },
    created() {},
    activated() {},
    methods: {
        getImgUrl4Exam,
        getDay(date) {
            return new Date(date).getDate()
        },
        getDate(date) {
            if (date != "") {
                return date.substr(0, 10)
            }
            return ""
        },
        getToday() {
            return new Date().toISOString().substr(0, 10)
        },
        openDlg(data = null, isReadOnly = false) {
            this.isReadOnly = isReadOnly
            this.dialog = true

            if (data) {
                this.qid = data.qid
                this.question_type = data.question_type
                this.question_text = data.question_text
                this.question_img = data.question_img
                this.answer = data.answer.toString()
                this.type = data.type
                this.level = data.level
                this.dimension = data.dimension ? data.dimension.toString() : ""
                this.dimension_name = data.dimension_name
            } else {
                this.clear()
            }
        },
        clear() {
            this.$nextTick(() => {
                this.$refs.form.reset()
            })

            this.qid = ""
            this.question_type = 2 //判断题
            this.question_text = ""
            this.question_img = ""
            this.answer = ""
            this.type = 0
            this.level = 0
            this.dimension = ""
            this.dimension_name = ""
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            // this.$v.$touch()

            // if (!this.$v.$invalid) {
            if (this.$refs.form.validate()) {
                // 用户确认后调用submit回调，将新的参数传递出去
                this.$emit("submit", {
                    qid: this.qid,
                    question_type: this.question_type,
                    question_text: this.question_text,
                    question_img: this.question_img,
                    answer: this.answer,
                    type: this.type,
                    level: this.level,
                    dimension: this.dimension,
                    dimension_name: this.dimension_name,
                })
                this.dialog = false
            }
        },
        delImg(img) {
            switch (img) {
                case 'question':
                    this.question_img = ""
                    break
            }
        },
        uploadSuccess4Question(id) {
            this.question_img = id
        },
    },
    components: {
        UploadSmall
    },
};
</script>

<style lang="scss" scoped>
.dlg-content {
    position: relative;
    .readonly-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.form-row {
    width: 100%;
    display: flex;
    align-items: center;
    &.between {
        justify-content: space-between;
    }
    &.start {
        justify-content: flex-start;
    }
    &.top {
        align-items: flex-start;
    }

    .col-one {
        // width: 380px;
        flex: 1;
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.top {
            align-items: flex-start;
        }
        &.middle {
            align-items: center;
        }
    }
    .col-two {
        flex: 1;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.top {
            align-items: flex-start;
        }
        &.middle {
            align-items: center;
        }
    }
    .option-num {
        font-size: 50px;
        font-weight: bold;
        margin-right: 20px;
    }
    .option-other {
        flex: 1;
    }
    .option-img-wrapper {
        margin-top: 12px;
    }
    .option-img {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .form-label {
        font-size: 14px;
        // color: #bbb;
        font-weight: bold;
    }
}
.no-scroll {
    ::-webkit-scrollbar {
        width: 0px;
    }
}
</style>
