<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="800">
            <v-card class="px-4 pt-3">
                <v-card-title class="">
                    <span class="text-h4 font-weight-bold"> {{ isReadOnly ? "查看试题（不可编辑）" : "编辑试题" }}</span>
                </v-card-title>
                <div class="form-content mx-12 dlg-content">
                    <v-form v-model="valid" ref="form" class="">
                        <div class="form-row">
                            <div class="col-one middle">
                                <div class="form-label mr-10" style="width: 30px">类型</div>
                                <v-select
                                    v-model="type"
                                    :items="typeMenu"
                                    label="类型"
                                    required
                                    :rules="[v => !!v || '请选择类型']"
                                ></v-select>
                            </div>
                            <div class="col-two middle">
                                <div class="form-label mr-10" style="width: 30px">级别</div>
                                <v-select
                                    v-model="level"
                                    :items="levelMenu"
                                    label="级别"
                                    required
                                    :rules="[v => !!v || '请选择级别']"
                                ></v-select>
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="form-row mb-5">
                            <div class="col-one">
                                <v-text-field
                                    label="题目名称"
                                    v-model="question_name"
                                    required
                                    :rules="[v => !!v || '请填写题目名称']"
                                ></v-text-field>
                            </div>
                            <div class="col-two right">
                                <v-btn
                                    tile
                                    dark
                                    color="deep-orange darken-1"
                                    class="font-weight-bold text-body-1 ml-8 "
                                    @click.stop="editOperateCourse()"
                                >
                                    <v-icon left> mdi-play </v-icon>
                                    编辑程序
                                </v-btn>
                            </div>
                        </div>
                        <div class="form-row top">
                            <!-- 题干文字 -->
                            <div class="col-one">
                                <v-textarea
                                    class="no-scroll"
                                    outlined
                                    label="任务目标"
                                    v-model="question_text"
                                    height="120"
                                    required
                                    :rules="[v => !!v || '请填写任务目标']"
                                ></v-textarea>
                            </div>
                            <!-- 题干图片 -->
                            <div class="col-two">
                                <div class="option-img" v-if="question_img != ''">
                                    <v-img
                                        :src="getImgUrl4Exam(question_img, 200, 132)"
                                        height="132"
                                        width="200"
                                        contain
                                    ></v-img>
                                    <v-icon color="#ccc" size="24" class="ml-2" @click.stop="delImg('question')"> mdi-close-circle </v-icon>
                                </div>
                                <UploadSmall
                                    v-else
                                    :prefix="prefix"
                                    @uploadSuccess="uploadSuccess4Question"
                                ></UploadSmall>
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="form-row operate-row" v-for="(evaluation, index) in evaluations" v-bind:key="index">
                            <v-text-field
                                :label="'评分项 ' + (index + 1)"
                                v-model="evaluation.content"
                            ></v-text-field>
                            <v-text-field
                                class="value"
                                label="分值"
                                v-model="evaluation.score"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        class="mx-1"
                                        icon
                                        height="32"
                                        width="32"
                                        outlined
                                        tile
                                        x-small
                                        color="purple"
                                        @click.stop="delEvaluation(index)"
                                    >
                                        <v-icon dark> mdi-minus </v-icon>
                                    </v-btn>
                                </template>
                                <span>删除评分项</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        class="mx-1"
                                        icon
                                        height="32"
                                        width="32"
                                        outlined
                                        tile
                                        x-small
                                        color="purple"
                                        @click.stop="addEvaluation(index)"
                                    >
                                        <v-icon dark> mdi-plus </v-icon>
                                    </v-btn>
                                </template>
                                <span>添加评分项</span>
                            </v-tooltip>
                        </div>
                    </v-form>
                    <!-- <div class="readonly-mask"></div> -->
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <template v-if="!isReadOnly">
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                    </template>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { getImgUrl4Exam } from "@/utils/util"
import { deleteImage } from "@/api/admin";
import UploadSmall from '@/views/components/widget/UploadSmall';

export default {
    name: "EditQuestionDlg4Operate",

    mixins: [validationMixin],
    validations: {
        question_name: { required },
        question_text: { required },
        type: { required },
        level: { required },
        dimension: { required },
    },

    data: function () {
        return {
            dialog: false,

            // 1:选择题, 2:判断题, 3:实操题
            question_type: 3,

            // 数据
            qid: '',
            question_name: '',
            question_text: '',
            question_img: '',
            type: 0,
            // type_text: '',
            level: 0,
            // level_text: '',
            dimension: '',
            dimension_name: '',

            evaluations: [{ content: '', score: '' }],

            levelMenu: [
                { text: '一级', value: 1 },
                { text: '二级', value: 2 },
                { text: '三级', value: 3 },
                { text: '四级', value: 4 },
                { text: '五级', value: 5 },
                { text: '六级', value: 6 },
            ],
            typeMenu: [
                { text: 'Scratch', value: 1 },
                { text: 'Python',  value: 2 },
                { text: 'C/C++',   value: 3 },
                { text: 'Blockly', value: 4 },
                { text: 'EV3', value: 5 },
                { text: 'Scratch硬件', value: 6 },
                { text: 'Python硬件',  value: 7 },
            ],

            prefix: 'exam',
            valid: true,

            isReadOnly: false,
        };
    },
    computed: {
    },
    created() {},
    activated() {},
    methods: {
        getImgUrl4Exam,
        getDay(date) {
            return new Date(date).getDate()
        },
        getDate(date) {
            if (date != "") {
                return date.substr(0, 10)
            }
            return ""
        },
        getToday() {
            return new Date().toISOString().substr(0, 10)
        },
        openDlg(data = null, isReadOnly = false) {
            this.isReadOnly = isReadOnly
            // refs.form.reset需要在对话框初始化完成后调用，所以先设置为true
            this.dialog = true

            if (data) {
                this.qid = data.qid
                this.question_type = data.question_type
                this.question_name = data.question_name
                this.question_text = data.question_text
                this.question_img = data.question_img
                this.type = data.type
                this.level = data.level
                this.dimension = data.dimension ? data.dimension.toString() : ""
                this.dimension_name = data.dimension_name

                this.evaluations = [{ content: '', score: '' }]
                if (data.evaluation != null && data.evaluation != '') {
                    let evaluations = data.evaluation.split(/[\n]/)
                    for (let i = 0; i < evaluations.length; i++) {
                        if (evaluations[i] != '') {
                            let kv = evaluations[i].split(';');
                            this.evaluations[i] = { 'content': kv[0], 'score': kv[1] }
                        }
                    }
                }

            } else {
                this.clear()
            }
        },
        clear() {
            this.$nextTick(() => {
                this.$refs.form.reset()
            })

            this.qid = ""
            this.question_type = 3 //判断题
            this.question_name = ""
            this.question_text = ""
            this.question_img = ""
            this.type = 0
            this.level = 0
            this.dimension = ""
            this.dimension_name = ""
            this.evaluations = [{ content: '', score: '' }]
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            if (this.$refs.form.validate()) {
                // 用户确认后调用submit回调，将新的参数传递出去
                let evaluationList = []
                for (let i in this.evaluations) {
                    if (this.evaluations[i].content != "") {
                        evaluationList.push(this.evaluations[i].content + ";" + this.evaluations[i].score)
                    }
                }

                this.$emit("submit", {
                    qid: this.qid,
                    question_type: this.question_type,
                    question_name: this.question_name,
                    question_text: this.question_text,
                    question_img: this.question_img,
                    type: this.type,
                    level: this.level,
                    dimension: this.dimension,
                    dimension_name: this.dimension_name,
                    evaluation: evaluationList.length > 0 ? evaluationList.join("\n") : ""
                })
                this.dialog = false
            }
        },
        delImg(img) {
            switch (img) {
                case 'question':
                    this.question_img = ""
                    break
            }
        },
        uploadSuccess4Question(id) {
            this.question_img = id
        },
        addEvaluation(index) {
            this.evaluations.splice(index + 1, 0, { content: '', score: '' })
        },
        delEvaluation(index) {
            this.evaluations.splice(index, 1);
        },
        editOperateCourse() {
            this.$router.push({ path: '/question/scratch', query: { pid: this.qid }})
        }
    },
    components: {
        UploadSmall
    },
};
</script>

<style lang="scss" scoped>
.dlg-content {
    position: relative;
    .readonly-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.divider {
    width: 100%;
    border-bottom: 1px dashed #ddd;
    margin: 15px 0 30px 0;
}
.form-row {
    width: 100%;
    display: flex;
    align-items: center;
    &.between {
        justify-content: space-between;
    }
    &.start {
        justify-content: flex-start;
    }
    &.top {
        align-items: flex-start;
    }

    .col-one {
        // width: 380px;
        flex: 1;
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.top {
            align-items: flex-start;
        }
        &.middle {
            align-items: center;
        }
    }
    .col-two {
        flex: 1;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.top {
            align-items: flex-start;
        }
        &.middle {
            align-items: center;
        }
        &.right {
            justify-content: flex-end;
        }
    }
    .option-num {
        font-size: 50px;
        font-weight: bold;
        margin-right: 20px;
    }
    .option-other {
        flex: 1;
    }
    .option-img-wrapper {
        margin-top: 12px;
    }
    .option-img {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .form-label {
        font-size: 14px;
        // color: #bbb;
        font-weight: bold;
    }
}
.operate-row {
    .value {
        width: 80px;
        max-width: 80px;
        margin-left: 20px;
        margin-right: 12px;
    }
}
.no-scroll {
    ::-webkit-scrollbar {
        width: 0px;
    }
}
</style>
